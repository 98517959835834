import axios from "axios";

const configureAxios = (backendURL) => {
  axios.defaults.baseURL = backendURL;
  axios.defaults.withCredentials = true;
};
export default configureAxios;

export const OPERATION_STATUS = {
  SUCCESS: 0,
  FAILURE: 1,
  NETWORK_NOT_REACHABLE: 3,
  NOT_FOUND: 4,
};

export const API_ERROR_CODE = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  INVALID_LOGIN_CREDENTIALS: 100,
  DUPLICATE_USER: 1004,
  ERROR_IN_FILE: 6001,

  // XML Error codes
  XML_PARSING_ERROR: 301, //Not a valid XML
  ENCRYPTION_DECRYPTION_ERROR: 302, //Not a valid XML

  // User Error codes
  USER_NOT_FOUND: 1001, //User not found
  INVALID_OLD_PASSWORD: 1002, //Invalid old password
  USER_INACTIVE: 1003, //User is not active
  USERNAME_ALREADY_EXIST: 1004, //username already exist
  ROLE_NOT_FOUND: 1005, //Role not found
  FAILED_TO_SEND_EMAIL: 1006, //
  ACCESS_NOT_FOUND: 1007, //Access not found
  USER_NOT_FOUND_TO_HANDLE_WORKLOAD: 3034, //No any user available to handle workload of original users


  BINDING_RESULT: 3000, //Invalid request Parameters
  SEARCH_PARAMETERS_CANT_BE_NULL: 3001, //Search Parameters cannot be all null

  // Loan Error codes
  LOAN_APP_NOT_FOUND: 2001, //Loan Application not found
  LOAN_APP_NOT_CREATED: 2002, //Loan Application not created
  LOAN_APP_INCOMPLETE: 2003, //Loan application was incomplete for more than 30 days.
  LOAN_APP_REJECTED: 2004, //Loan application was rejected for more than 90 days
  LOAN_UPDATE_NOT_ALLOWED: 2005, //Loan update Not Allowed
  LOAN_STATUS_UPDATE_NOT_ALLOWED: 2006, //Loan status update Not Allowed
  LOAN_OTP_CREATE_FAILED: 2007, //OTP generation failed. Please try again.
  LOAN_OTP_NOT_FOUND: 2008, //Loan OTP not found.
  LOAN_ANALYSIS_NOT_FOUND: 2009, //Analysis data not found
  LOAN_OTP_NOT_CREATE_ALLOWED: 2010, //Loan OTP create not allowed.
  INSUFFICIENT_LOAN_APPLICATION_DATA: 2011, //
  LOAN_APP_INVALID_STATUS: 2012, // INVALID_AGE:2013,    //

  // Documents
  DOCUMENT_NOT_FOUND: 4001, //Document not found
  DOCUMENT_ALREADY_UPLOADED: 4002, //
  INSUFFICIENT_DOCUMENTS: 4003, //
  DOCUMENT_DELETE_NOT_ALLOWED: 4004, //

  // Analyst
  LOAN_APP_ASSIGNED: 5001, //Sorry! loan application assigned by some other analyst
  LOAN_APP_NOT_ASSIGNED: 5002, //Loan application not assigned yet
  LOAN_APP_SEARCH_NOT_ALLOWED: 5003, //Sorry you cannot search through this status
  LOAN_APP_KONTOMATIK_NOT_AUTHORIZED: 5004, //Kontomatic authorization not done for application
  LOAN_ANALYST_INFO_NOT_COMPLETE: 5005, //Analysis data is not completed
  LOAN_CREDIT_SCORE_ERROR: 5006, //Error in calculating credit score
  LOAN_ANALYSIS_RECORD_NOT_FOUND: 5007, //loan analysis record not found
  CASH_TOP_UP_LOAN_IS_ALREADY_ACTIVE: 2065,//The minimart already has an active cash top up loan. The requested loan cannot be approved. Please approve the loan with conciliation.
  INVALID_LOAN_APPROVAL_REQUEST: 2066,//The minimart already has 2 active loans. The requested loan cannot be approved. Please approve the loan with conciliation.
  TRANSFER_RECEIPT_NOT_FOUND: 2071,//Please upload transfer receipt to disburse loan.
  PAYMENT_CANNOT_BE_REVERTED: 11097,//Payment cannot be reverted

  // Files Error codes
  FILE_UPLOAD_FAIL: 6001, //Failed to upload file
  FILE_NOT_FOUND: 6002, //File not found
  INDEX_REQUIRED: 6003, //Index required for requested subtype
  FILE_UPLOAD_NOT_ALLOWED: 6008, //File upload not allowed.

  // Loan notes
  LOAN_NOTE_NOT_FOUND: 7001, //Note not found.
  LOAN_NOTE_DELETE_NOT_ALLOWED: 7002, //Note delete not allowed.
  LOAN_NOTE_UPDATE_NOT_ALLOWED: 7003, //Note update not allowed.
  LOAN_NOTE_CREATE_FAILED: 7004, //Note not found.
  LOAN_NOTE_CREATE_NOT_ALLOWED: 7005, //Note create not allowed.

  // Europhile client info error codes
  CLIENT_INFO_NOT_FOUND: 8001, //CLIENT_INFO_NOT_FOUND
  INVALID_EUROPHIL_DATA: 8002, //INVALID_DATA
  // Europhile client transactions error codes
  CLIENT_TRANSACTIONS_NOT_FOUND: 8003, //CLIENT_TRANSACTIONS_NOT_FOUND
  CLIENT_LOAN_MAPPING_NOT_FOUND: 8004, //CLIENT_LOAN_MAPPING_NOT_FOUND

  // Credit score error codes.
  //	NO_ACTIVE_ALGORITHM_FOUND:9001,    //No active credit score algorithm found.
  //	INVALID_ALGORITHM_FORMAT:9002,    //Invalid algorithm JSON string.
  VERSION_ALREADY_EXIST: 9003, //Version already exist.
  //	NO_ALGORITHM_FOUND:9004,    //No algorithm found.
  //	DRAFTED_EXISTS:9005,    //Cannot create new algo as there is already one drafted.
  //	NOT_ALLOWED_TO_UPDATE_CREDIT_SCORE:9006,    //Not allowed to update credit score algo as some other user drafted it.
  //	DRAFTED_NOT_EXISTS:9007,    //No drafted algorithm found.
  ALGORITHM_CANNOT_BE_ACTIVATED: 9008, //Algorithm is not in drafted state cannot be activated.

  // Branch management error codes
  BRANCH_NOT_FOUND: 10001, //Branch not found.
  BRANCHS_NOT_DEFINED: 10002, //Branches not defined.
  BRANCH_ALREADY_EXIST: 10003, //Branch with same name, already exists.

  //EMI configuration management error codes
  NO_ACTIVE_CONFIGURATION_FOUND: 11001, //No active configuration found.
  INVALID_CONFIGURATION_FORMAT: 11002, //Invalid configuration JSON string.
  NO_DRAFTED_CONFIGURATION_FOUND: 11003, //No drafted configuration found.
  NO_CONFIGURATION_FOUND: 11004, //No configuration found.
  DRAFTED_CONFIGURATION_ALREADY_EXISTS: 11005, //Cannot create new configuration, as there is already one drafted.
  CONFIGURATION_CANNOT_BE_ACTIVATED: 11006, //Configuration is not in drafted state, hence cannot be activated.
  NOT_ALLOWED_TO_UPDATE_CONFIGURATION: 11007, //Not allowed to update configuration, as some other user drafted it.
  DEPOSIT_NOT_ALLOWED: 11088,

  //Take off late fees
  LOAN_NOT_FOUND: 2018,
  INVALID_NEGOTIATION_REQUEST: 2019,
  NEGOTIATION_NOT_FOUND: 2020,
  INVALID_NEGOTIATION_AMOUNT: 2021,
  INVALID_NEGOTIATION_RESPONSE: 2022,
  INVALID_NEGOTIATION_STATE: 2023,
  INVALID_NEGOTIATION_LATE_FEES: 2024,
  INVALID_NEGOTIATION_INTEREST: 2025,
  NO_RECORDS_FOUND: 11141,
  LOW_CREDIT_SCORE_APP_REJECTED: 2033,
  OPERATIONS_OFFICER_APPROVAL_FAILED: 11201,
  LOAN_APPILCATION_SIGNATURE_NOT_FOUND: 11182,
  INSUFFICIENT_CREDIT_LIMIT: 2027,
  PRODUCT_CATEGORY_ALREADY_EXISTS: 11220,
  PRODUCT_ALREADY_EXISTS: 11223,
  INVALID_LATE_FEES_REQUEST: 2049,

  //Deactivate User
  INVALID_BRANCH_COUNT: 3006,
  USER_DEACTIVATION_FAILED: 3007,
  NO_ADJACENT_USER_FOUND: 3008,
  INVALID_USER_DEACTIVATION_REQUEST: 3009,
  LOAN_REJECTED_DUE_TO_LOW_GRADE: 2031,
  LOAN_REJECTED_DUE_TO_NEGATIVE_REFERENCES: 11192,
  CANNOT_CHANGE_ASSIGNMENT: 11064,

  SAME_DATE_LOAN_CANCEL_FAIL: 2034,
  ACCOUNT_LOCKED_ERROR: 1000,
  NEGOTIATION_EXPIRED: 2035,
  NEGOTIATION_EMPTY_FIELD_ERROR: 5008,

  //Salesperson assignment
  SALESPERSON_ALREADY_ASSIGNED: 11068,
  PERMANENT_ASSIGNMENT_NOT_FOUND: 3013,
  SALESPERSONS_VISIT_IS_ALREADY_PLANNED: 11069,
  HOLIDAY_ERROR: 11242,
  HOLIDAY_ASSIGNMENT_ERROR: 11243,
  SALESPERSON_POSITION_NOT_FILLED_ROUTE: 3019,

  INVALID_AMOUNT: 2044, //Please pay a 50% amount of the active cash refill loan to get the new one
  POSITION_NOT_FOUND: 3016,
  POSITION_NAME_ALREADY_PRESENT: 3023,

  BRANCH_ZONE_MAPPING_NOT_FOUND: 10006,
  ROUTE_NOT_FOUND: 16001,

  NO_MESSENGER_FOUND_FOR_NEW_POSITION: 19002,
  NO_MESSENGER_FOUND_IN_THE_ZONE: 19003,
  INVALID_CREDIT_LIMIT_AMOUNT: 18001,
  MESSENGER_POSITION_NOT_FILLED_FOR_ROUTE: 3020,
  NOTE_NOT_FOUND: 11112,
  REQUEST_NOT_FOUND: 12014,
  MINIMART_NOT_FOUND: 11040,
  REMITTANCE_CORE_CONNECTION_PROBLEM: 2052,
  INVALID_AMOUNT_ON_PROMOTION: 12025,
  MULTIPLE_ACTIVE_LOANS: 2042,
  INVALID_LOAN_COMBINATION: 2043,

  //REWARDS
  INSUFFICIENT_REWARD_POINTS: 11228,
  PRODUCT_NOT_FOUND: 11222,
  MINIMART_BLOCKED: 11047,
  PAYMENT_NOT_FOUND: 11094,
  REVERT_PAYMENTS_NOT_POSSIBLE: 11095,

  //Messenger Assign
  MESSENGER_ALREADY_ASSIGNED: 11070,
  NOTHING_TO_COLLECT: 11096,
  ALREADY_ASSIGNED_POSITION: 3031,

  INVALID_LOAN_STATE_CHANGE_REQUEST: 2053,
  //Invoice
  INVOICE_NOT_FOUND: 11042,
  ROUTE_NOT_ASSIGNED_FOR_MINIMART: 3021,

  DISAPPEARED_LOAN_CANNOT_PAYOFF: 2054,
  INVALID_COLLECTION_CYCLE_CHANGE_REQUEST: 2057,
  INVALID_COLLECTION_CYCLE_CHANGE: 2058,

  //Credo
  CREDO_DATASET_INSIGHT_RESPONSE_PARSING_FAILED: 12039,
  CREDO_LOAN_APPLICATION_NOT_FOUND: 12040,

  //MetaMap
  METAMAP_AUTHENTICATION_FAILED: 19110,
  FAILED_RO_GET_MEDIA_URL: 19111,


  //Add new zone
  BRANCH_CANT_BE_NULL: 3003,
  ZONE_ALREADY_EXISTS: 10008,
  NO_MESSENGER_FOUND_FOR_GIVEN_POSITION: 19004,

  //Blacklist client
  OWNER_OF_MINIMART_IS_BLACKLISTED: 11049,
  INVALID_CEDULA_UPDATE_REQUEST: 20002,
  CEDULA_DETAILS_MISMATCH: 20003,

  //Signup request
  CLIENT_ALREADY_EXIST: 3032,
  CLIENT_NOT_FOUND: 20001,

  //Supplier configuration
  ACCOUNT_DETAILS_NOT_FOUND: 3033,
  REQUEST_ALREADY_EXISTS: 12015,
  INVALID_REQUEST: 12011,
  SUPPLIER_ALREADY_EXIST: 2074,
  RNC_NOT_FOUND: 11204,
  RNC_SERVICE_NOT_ACCESSIBLE: 11205,
  ACCOUNT_NUMBER_PATTERN_MISMATCH: 2079,

  //loan details
  REPORT_FOR_DISBURSED_LOAN: 11142,
  REPORT_NOT_AVAILABLE_FOR_OLD_LOAN: 11143,
  EMI_CALCULATION_NOT_FOUND: 11010,
  RAISE_REMITTANCE_BEFORE_DISBURSEMENT: 2080,
  INVALID_BASIC_INFO_DATA: 2081,
  INVALID_INCOME_DATA: 2082,
  INVALID_SITE_VISIT_DATA: 2083,
  INVALID_EXPENSES_DATA: 2084,

  //Change role
  USER_CANT_CHANGE_ROLE_ITSELF: 23001,
  USER_HAVE_SUBORDINATES_TO_CHANGE_ROLE: 23002,
  USER_HAVE_ONGOING_LOANS_TO_CHANGE_ROLE: 23003,
  NO_ADJACENT_USER_FOUND_TO_CHANGE_ROLE: 23004,

  //Negotialtion changes
  AMOUNT_APPROVED_CHANGE_NOT_ALLOWED: 2087,
  NO_DUE_INSTALLMENT_FOUND_FOR_CHANGIING_COLLECTION_CYCLE: 2088,
  NEGOTIATION_UPDATE_NOT_ALLOWED: 2089,
  NEGOTIATION_UPDATE_BY_ANALYST: 2090,
  NEGOTIATION_UPDATE_BY_OPERATION_USER: 2091,
  INVALID_FREQUENCY: 1201,
  INVALID_COLLECTION_DAYS: 1202,
  FREQUENCY_SHOULD_NOT_BY_EMPTY: 1203,
  MINIMART_CANNOT_BE_MARKED_NORMAL: 11051,
  INVALID_LOAN_AMOUNT_REQUEST: 2038,
  INVALID_INSTALLMENT_DATE_FOR_CHANGE_COLLECTION_CYCLE: 2092,

  //Loan payment
  DUE_LOAN_NOT_FOUND: 11090,
  COLLECTION_FAILED: 11091,
  INVALID_DOWNPAYMENT: 11092,
  MOTORCYCLE_NOT_FOUND: 11226,
  INSUFFICIENT_ADMINISTRATIVE_EXPENSE_AMOUNT: 2093,
  LOAN_NOT_FOUND_FOR_COLLECTION: 2094,

  //Grade upgrade
  MINIMART_WITH_GRADE_A_CANNOT_BE_UPGRADED: 24001,
  MINIMART_WITH_GRADE_E_CANNOT_BE_DOWNGRADED: 24002,
  NO_ANY_DOWNPAYMENT_FOUND: 23100,
  CHOOSE_TWO_DIFFERENT_MINIMARTS: 25001,
  BRANCH_ZONE_MAPPING_NOT_PRESENT: 10010,

  //Concilation
  CONCILIATION_DISBURSMENT_IS_ALREADY_IN_PROGRESS: 2101,
  CONCILIATED_LOAN_IS_ALREADY_DISBURSED: 2102,
  NEW_LOAN_AMOUNT_NOT_SUFFICIENT: 2098,
  ACTIVE_CASHTOPUP_LOAN_FOUND: 2100,
  MULTIPLE_LOANS_CANT_BE_CONCILIATED: 2099,
  AMOUNT_IS_NOT_VALID: 2103,
};
